import React, { useCallback, useRef, useState } from 'react';
import classNames, { classnames } from 'tailwindcss-classnames';

import { Icon } from 'src/common';
import { twMerge } from 'src/lib/mergeTailwind';
import { useModel } from 'src/lib/hooks';

const iconSize = {
  small: 'sm',
  default: 'md',
  rectangular: 'base',
};

const loadingIconSize = {
  small: 'w-2 h-2',
  rectangular: 'w-5 h-5',
  default: 'w-10 h-10',
};

const iconColor = {
  light: 'violet',
  dark: 'white',
  blue: 'mystic',
  transparent: 'violet',
};

const Button = ({
  onClick,
  color = 'light',
  variant = 'default',
  disabled = false,
  loading: _loading = false,
  children,
  className,
  labelClassName = '',
  fontFamily = 'font-content',
  iconLeft,
  iconRight,
  href,
  target,
  hideLabelWhenLoading = false,
  blockScreenWhenLoading = false,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(_loading);
  const onClickRef = useRef(onClick);
  const { setInterface } = useModel.userInterface.dispatch();
  onClickRef.current = onClick;

  const handleClick = useCallback(
    async (...props) => {
      try {
        setLoading(true);
        if (blockScreenWhenLoading) {
          setInterface({ isLoading: true });
        }
        await onClickRef.current(...props);
      } finally {
        setLoading(false);
        if (blockScreenWhenLoading) {
          setInterface({ isLoading: false });
        }
      }
    },
    [setLoading, setInterface, blockScreenWhenLoading, onClickRef]
  );

  const isLoading = loading || _loading;
  const hideChildren = hideLabelWhenLoading && isLoading;
  const effectiveColor = isLoading ? 'dark' : color;
  let iconRightComp = iconRight && (
    <Icon
      name={iconRight}
      color={iconColor[effectiveColor]}
      size={iconSize[variant]}
    />
  );

  if (isLoading) {
    iconRightComp = (
      <Icon name="beachBall" className={loadingIconSize[variant]} />
    );
  }

  const buttonClassName = twMerge(
    classNames(
      {
        'bg-violet text-white shadow-lg focus:ring-violet border-none':
          effectiveColor === 'dark',
        'bg-white text-violet focus:ring-mystic': effectiveColor === 'light',
        'bg-curious-blue-light text-violet focus:ring-violet':
          effectiveColor === 'blue-light',
        'bg-curious-blue-dark text-white border-curious-blue-dark focus:ring-mystic':
          effectiveColor === 'blue',
        'bg-transparent text-violet focus:ring-violet border-none':
          effectiveColor === 'transparent',
      },
      {
        'xs:px-17px h-12 2xl:h-14 border-2': variant === 'default',
        'flex-row space-x-2 px-2 xs:px-3 h-6 2xl:h-14 w-full':
          variant === 'menuMobile',
        'px-3 h-6': variant === 'small',
        'py-3 px-5 rounded-xl text-sm space-x-2': variant === 'rectangular',
        'rounded-full font-semibold space-x-1': variant !== 'rectangular',
      },
      'flex items-center font-heading transition',
      'focus:ring-2 focus:ring-offset-2 focus:outline-none focus:opacity-90',
      'disabled:cursor-not-allowed disabled:bg-input-lighter'
    ),
    className
  );

  const spanClassName = twMerge(
    classnames(fontFamily, 'inline-block', {
      'text-base leading-4': variant === 'default',
      'text-base font-bold leading-4': variant === 'menuMobile',
      'text-xs 2xl:text-sm font-light': variant === 'small',
      'mx-auto': !iconLeft && !iconRight,
      'sr-only': !children,
    }),
    labelClassName
  );

  const content = (
    <>
      {iconLeft && (
        <Icon
          name={iconLeft}
          color={iconColor[effectiveColor]}
          size={iconSize[variant]}
        />
      )}
      {!hideChildren && <span className={spanClassName}>{children}</span>}
      {iconRightComp}
    </>
  );

  return !href ? (
    <button
      disabled={isLoading || disabled}
      onClick={!isLoading && !disabled ? handleClick : null}
      className={buttonClassName}
      {...buttonProps}
    >
      {content}
    </button>
  ) : (
    <a
      href={href}
      className={buttonClassName}
      target={target}
      onClick={onClick}
      rel={target === '_blank' ? 'noreferrer' : ''}
    >
      {content}
    </a>
  );
};

export default Button;
