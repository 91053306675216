const toBoolean = (stringValue) => {
  return String(stringValue).toLowerCase() === 'true';
};

const config = {
  debug: process.env.REACT_APP_DEBUG,
  a11y: process.env.REACT_APP_A11Y,
  productSelector: toBoolean(process.env.REACT_APP_PRODUCT_SELECTOR),
  infoWebPageUrl: process.env.REACT_APP_INFO_WEB_PAGE_DOMAIN,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  datadog: {
    env: process.env.REACT_APP_DATADOG_ENV,
    appId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  },
  slide: {
    locked: process.env.REACT_APP_SLIDE_LOCKED,
    baseUrl: process.env.REACT_APP_SLIDE_BASEURL,
    clientId: process.env.REACT_APP_SLIDE_CLIENTID,
    clientSecret: process.env.REACT_APP_SLIDE_CLIENTSECRET,
  },
  melissaData: {
    licenseKey: process.env.REACT_APP_MELISSADATA_LICENSEKEY,
    expressEntryEndpoint:
      process.env.REACT_APP_MELISSADATA_EXPRESSENTRYENDPOINT,
    globalExpressEntryEndpoint:
      process.env.REACT_APP_MELISSADATA_GLOBALEXPRESSENTRYENDPOINT,
  },
  sessionTime: process.env.REACT_APP_SESSION_TIME || 35,
  oneIncWebhook: process.env.REACT_APP_ONEINC_WEBHOOK,
};

export default config;
