import { BasicLayout, Button, Card, Footer } from 'src/common';

const RetryPage = ({ submit, handleStartOver }) => {
  return (
    <BasicLayout>
      <Card
        icon="seashells"
        title="Aw, shucks!"
        subtitle="Something went wrong. Please try again."
      >
        <div className="flex justify-center">
          <Button
            color="dark"
            variant="rectangular"
            className="w-36 justify-center"
            onClick={submit}
          >
            Try Again
          </Button>
        </div>
      </Card>
      <Footer>
        <Footer.Back
          rounded
          hideIcon={true}
          label="Exit"
          onClick={handleStartOver}
          className="w-105px"
        />
      </Footer>
    </BasicLayout>
  );
};
export default RetryPage;
