import { useSelector, useDispatch } from 'react-redux';

/* eslint-disable react-hooks/rules-of-hooks */
function createModelHook(key) {
  let model = () => useSelector((state) => state[key]);
  model.dispatch = () => useDispatch()[key];
  return model;
}

const useModel = {
  currentPhase: createModelHook('currentPhase'),
  quote: createModelHook('quote'),
  session: createModelHook('session'),
  user: createModelHook('user'),
  updated: createModelHook('updated'),
  userInterface: createModelHook('userInterface'),
};

useModel.quote.defaults = (options = {}) => {
  const {
    pageIndex,
    furthestIndex,
    pendingChanges: existingQuote,
  } = useModel.session();

  const isHistorical = pageIndex < furthestIndex;
  return isHistorical || options?.forceDefaults
    ? existingQuote
    : Object.assign({}, options?.initialValues);
};

export default useModel;
