import dayjs from 'dayjs';
import isEmail from 'validator/lib/isEmail';

const dateFormat = 'MM/DD/YYYY';
const validEmailRegex = new RegExp(
  '^[\\w\\d\\.\\-\\_\\+]+@[\\w\\d\\.\\-\\_\\+]+$'
);

export const parseDate = (value) => {
  const date = dayjs(value);

  if (!date.isValid()) {
    return null;
  }

  return date.format('MM/DD/YYYY') === value ||
    date.format('YYYY-MM-DD') === value
    ? date
    : null;
};

export const validateBirthDate = (value, minAge = 18, maxAge = 99) => {
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  let year = value.substr(value.length - 4, value.length);
  if (year < 1000) {
    return 'Invalid date.';
  }

  const minDate = dayjs().subtract(maxAge, 'year');
  const maxDate = dayjs().subtract(minAge, 'year');

  const tooYoung = Math.floor(maxDate.diff(date, 'day', true)) < 0;
  const tooOld = Math.floor(minDate.diff(date, 'day', true)) > 0;

  if (tooOld) {
    return `Date of Birth must be after ${minDate.format(dateFormat)}.`;
  }

  if (tooYoung) {
    return `You must be at least 18 years old to get a quote`;
  }

  return true;
};
export const validateExpression = (expresion, errorMessage) => {
  return expresion || errorMessage;
};

export const validateYear = (year) => {
  let currentYear = dayjs().year();
  if (year <= 0 || !dayjs(year, 'YYYY').isValid()) {
    return 'Must be a valid year.';
  }
  if (year < 1900 || year > currentYear) {
    return `Must be between 1900 and ${currentYear}.`;
  }
  return true;
};

export const validateEffectiveDate = (value) => {
  const currentDate = dayjs().startOf('day');
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  const tooSoon = date.diff(currentDate, 'day', true) < 0;
  const tooLate = date.diff(currentDate, 'day', true) > 60;

  if (tooSoon || tooLate) {
    return 'Must be within the next 60 days.';
  }

  const minDay = dayjs('09/01/2021', dateFormat, true);

  if (minDay.isAfter(value)) {
    return 'Must be on or after 9/1/2021.';
  }

  return true;
};

export const validateInspectionDate = (value) => {
  const currentDate = dayjs();
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  const tooSoon = date.diff(currentDate, 'year', true) < -5;
  const tooLate = date.diff(currentDate, 'day', true) > 0;

  if (tooSoon || tooLate) {
    return 'Must be within the last 5 years.';
  }

  return true;
};

export const validateEmail = (value) => {
  if (!isEmail(value)) {
    return 'Invalid email.';
  }

  if (!validEmailRegex.test(value)) {
    return 'Invalid email.';
  }

  return true;
};

export const validateHighRiseFloorNumber = (value) => {
  if (value < 1 || value > 99) {
    return 'Number must be between 1 and 99.';
  }
  return true;
};

export const validateLowRiseFloorNumber = (value) => {
  if (value < 1 || value > 6) {
    return 'Invalid floor.';
  }
  return true;
};

export const validatePriorExpirationDate = (value) => {
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  return true;
};

export const validateMonthAndYear = (monthYear) => {
  try {
    let customParseFormat = require('dayjs/plugin/customParseFormat');
    dayjs.extend(customParseFormat);
    const [month, year] = monthYear.split('/');
    const date = `${year}/${month}/14`;
    const currentDate = dayjs().startOf('month');
    if (dayjs(date, 'YYYY/MM/DD', true).isValid()) {
      if (currentDate.isAfter(date)) {
        return 'Must be in the future.';
      }
      return true;
    } else {
      return `Invalid Value.`;
    }
  } catch (error) {
    return `Invalid Value.`;
  }
};
