import config from 'src/config';

const { infoWebPageUrl } = config;

export const links = {
  slideWelcomePage: infoWebPageUrl,
  privacyPolicy: `${infoWebPageUrl}/privacy-policy`,
  termsOfUse: `${infoWebPageUrl}/terms`,
};

export const limitations = {
  INT_MAX: 2147483647,
};
