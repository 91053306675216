import React from 'react';

import { Icon } from 'src/common';
import ProgressBar from './ProgressBar';
import { twMerge } from 'src/lib/mergeTailwind';

const titleSizes = {
  small: 'text-2xl font-bold',
  normal: 'text-2xl md:text-3xl font-extrabold',
};

const Card = React.forwardRef(
  (
    {
      icon,
      iconColor = 'violet',
      title,
      titleClassName,
      titleSize = 'normal',
      subtitle,
      children,
      className,
      contentClassName,
      childrenClassName,
      progressBar = null,
      displayEffectiveDate = true,
      containerClassName,
      ...containerProps
    },
    ref
  ) => {
    titleClassName = twMerge(
      'text-violet',
      titleSizes[titleSize],
      titleClassName
    );

    return (
      <div
        className={twMerge(
          'bg-white relative rounded-t-2xl shadow-card z-20',
          containerClassName
        )}
        ref={ref}
        {...containerProps}
      >
        {progressBar && (
          <div className="block mt-8">
            <ProgressBar
              {...progressBar}
              displayEffectiveDate={displayEffectiveDate}
            />
          </div>
        )}
        <div
          className={twMerge(
            'flex flex-col items-center relative px-3 py-8',
            'md:px-6',
            'lg:p-6',
            className
          )}
        >
          <div
            className={twMerge(
              'flex flex-col items-center max-w-xl text-center mb-5',
              contentClassName
            )}
          >
            {icon && (
              <Icon name={icon} color={iconColor} size="3xl" className="mb-5" />
            )}
            <h1 className={titleClassName} data-testid="title">
              {title}
            </h1>
            {subtitle && (
              <h2
                className="mt-2 text-center text-input"
                data-testid="subtitle"
              >
                {subtitle}
              </h2>
            )}
          </div>
          <div className={childrenClassName ?? 'w-full sm:px-6'}>
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default Card;
