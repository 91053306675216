import { Icon } from 'src/common';

const LoadingPage = ({ text = 'We’re spinning that up...' }) => (
  <div className="fixed inset-0 bg-gradient-to-b from-loading-background-start via-loading-background-middle to-loading-background-end z-50">
    <div className="absolute top-1/2 bottom-0 w-full">
      <div className="absolute -top-32 z-10 w-full flex flex-col items-center">
        <Icon name="beachBallShadow" size="6xl" />
        <p
          role="alert"
          className="text-violet text-2xl text-center font-bold px-4"
        >
          {text}
        </p>
      </div>
    </div>
  </div>
);

export default LoadingPage;
