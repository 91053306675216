export const getMappedPages = ({ pages, sectionActions = (page) => page }) => {
  const pagesList = Object.entries(pages).reduce(
    (newPagesList, [displayName, page]) => {
      page.displayName = displayName;
      return [...newPagesList, page];
    },
    []
  );

  return pagesList.map(sectionActions);
};

export const getPagesWithSections = (pagesBySection) => {
  return Object.entries(pagesBySection).reduce(
    (newPagesList, [section, sectionPages]) => {
      const pagesList = getMappedPages(sectionPages);
      return [
        ...newPagesList,
        ...pagesList.map((page) => {
          return { section: section, PageContent: page };
        }),
      ];
    },
    []
  );
};

export const descendantProperty = (
  object,
  fieldString,
  defaultValue = undefined
) => {
  if (!object) {
    return defaultValue;
  }
  try {
    return fieldString
      .split('.')
      .reduce((iterateObject, field) => iterateObject[field], object);
  } catch (e) {
    return defaultValue;
  }
};

export const isSelectOption = (value, options) => {
  return new Set(options).has(value);
};

export const formatDataTestId = (dataTestId) => {
  return dataTestId.replace(/ /g, '');
};
