import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import classnames from 'tailwindcss-classnames';
import PackageOption from './PackageOption';

const PackageSelector = ({
  name,
  control,
  defaultValue,
  rules,
  ariaControls,
  children,
  disabled = false,
  error,
  containerClassName,
  iconSize = '4xl',
  optionsClassname,
  afterChange = (value) => {},
}) => {
  if (rules?.required) {
    let customError = rules.required.message ?? rules.required;
    let customValidate = rules.validate;

    rules.validate = (v) => {
      if (v !== undefined) {
        return customValidate ? customValidate(v) : true;
      }
      return customError;
    };

    rules.required = false;
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value, ref }) => (
        <>
          <div
            className={classnames(
              containerClassName ??
                'flex flex-row flex-wrap items-center justify-center space-y-4 sm:space-y-0'
            )}
          >
            {React.Children.map(
              children,
              (child, i) =>
                child &&
                React.cloneElement(child, {
                  name,
                  id: name + i,
                  ariaControls,
                  onChange: (e) => {
                    onChange(e);
                    afterChange(e);
                  },
                  selectedValue: value,
                  error: error,
                  disabled: disabled,
                  inputRef: i === 0 && ref,
                  iconSize: child.props.iconSize || iconSize,
                  className: classnames(
                    child.props.className,
                    optionsClassname
                  ),
                })
            )}
          </div>
          <div className="text-error text-center text-sm mt-6">{error}</div>
        </>
      )}
    />
  );
};

const Option = ({
  label,
  value,
  disabled,
  name,
  id,
  ariaControls,
  onChange,
  className,
  selectedValue,
  icon,
  iconSize,
  inputRef,
  error,
  premium,
}) => {
  useEffect(() => {
    if (disabled && value === selectedValue) {
      onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <PackageOption
      label={label}
      value={value}
      disabled={disabled}
      name={name}
      id={id}
      ariaControls={ariaControls}
      onChange={onChange}
      className={className}
      selectedValue={selectedValue}
      icon={icon}
      iconSize={iconSize}
      inputRef={inputRef}
      premium={premium}
      error={error}
    />
  );
};

PackageSelector.Option = Option;

export default PackageSelector;
