import { classnames } from 'tailwindcss-classnames';

import { Icon } from 'src/common';
import { currencyTransform } from 'src/lib/transforms';

const Premium = ({
  label = 'Annual Premium',
  premium,
  refreshing,
  needsRefresh,
  onRefresh,
  disabled = false,
  className,
  hideLabel = false,
}) => {
  const boxClasses =
    'flex flex-col justify-center items-center h-51px w-28 xs:w-151px mx-2 rounded-md';

  if (needsRefresh || refreshing) {
    return (
      <button
        onClick={onRefresh}
        className={classnames(
          'bg-curious-blue space-y-1 focus:outline-none focus:ring-2 focus:ring-mystic focus:ring-offset-2 focus:ring-offset-violet',
          boxClasses
        )}
      >
        <Icon
          name="redo"
          color="white"
          size="md"
          className={refreshing && 'animate-spin'}
        />
        {!hideLabel && (
          <span className="text-xs text-white">Refresh Premium</span>
        )}
      </button>
    );
  }
  return (
    <div
      className={classnames(
        { 'bg-white': !disabled, 'bg-input-lighter': disabled },
        boxClasses
      )}
    >
      <span
        className={classnames(
          { 'text-violet': !disabled, 'text-white': disabled },
          'text-xs '
        )}
      >
        {label}
      </span>
      <strong
        className={classnames(
          { 'text-violet': !disabled, 'text-white': disabled },
          'text-lg font-bold leading-none',
          'xs:text-2xl',
          className
        )}
      >
        {currencyTransform.input(premium)}
      </strong>
    </div>
  );
};

export default Premium;
