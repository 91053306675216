import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { useAuth0 } from 'src/lib/hooks';
import { InactivityModal } from 'src/common';

const defaultOptions = {
  secured: true,
};

const getEndSessionDate = (user) => {
  const expiredTime = dayjs().add(user?.expiresIn, 's');
  return dayjs(user?.sessionTimeout).isBefore(expiredTime)
    ? dayjs(user?.sessionTimeout)
    : expiredTime;
};

const withSession = (Component, options = defaultOptions) => {
  const secured = options.secured ?? defaultOptions.secured;

  const Page = (props) => {
    const history = useHistory();
    const { user, logout, refreshSession } = useAuth0();
    const [modalVisible, setModalVisible] = useState(false);

    // handle token and session expiration
    useEffect(
      () => {
        let logoutTimeoutId;
        let showModalTimeoutId;
        let mounted = true;

        if (user?.expiresIn > 0 && mounted) {
          const now = dayjs();
          const endSessionDate = getEndSessionDate(user);
          const showModalIn = endSessionDate
            .clone()
            .subtract(300, 's')
            .diff(now, 'ms');

          const logoutIn = endSessionDate.diff(now, 'ms', true);

          showModalTimeoutId = setTimeout(
            () => setModalVisible(true),
            showModalIn
          );
          logoutTimeoutId = setTimeout(() => {
            logout('/resume', { expired: true });
          }, logoutIn);
        }
        return () => {
          clearTimeout(showModalTimeoutId);
          clearTimeout(logoutTimeoutId);
          mounted = false;
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        user?.expiresIn,
        user?.token,
        logout,
        setModalVisible,
        user?.sessionTimeout,
      ]
    );

    useLayoutEffect(() => {
      const check = async () => {
        const endSessionDate = getEndSessionDate(user);
        if (!user) {
          if (secured) {
            history.replace('/');
          }
        } else if (dayjs(endSessionDate).isBefore()) {
          logout();
        } else if (user.hasSession && !user.loggingIn && secured) {
          await refreshSession();
        }
      };
      check();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const continueSession = async () => {
      if (user) {
        await refreshSession();
        setModalVisible(false);
      }
    };

    return !user && secured ? (
      <></>
    ) : (
      <>
        <Component {...props} />
        <InactivityModal
          modalVisible={modalVisible}
          onContinue={continueSession}
        />
      </>
    );
  };

  Page.displayName = Component.displayName;

  return Page;
};

export default withSession;
