import React, { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import axios from 'axios';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import { DropdownMenu } from 'src/common';
import { useDebouncedSearch } from 'src/lib/hooks';
import config from 'src/config';
import debugData from 'src/lib/debugData';
import AptInput from './AptInput';

const expressEntryEndpoint = config.melissaData.expressEntryEndpoint;
const globalExpressEntryEndpoint =
  config.melissaData.globalExpressEntryEndpoint;
const maxrecords = 20;

const defaultTransform = {
  input: (value) =>
    value && {
      Address1: value.Address1,
      Address2: value.Address2,
      City: value.City,
      State: value.State,
      ZipCode: value.ZipCode,
      formatted: `${value.Address1} ${value.Address2}, ${value.City}, ${value.State} ${value.ZipCode}`,
      apt: value.Address2,
      SuiteName: null,
      SuiteList: value.Address2 ? [value.Address2] : [],
      SuiteCount: value.Address2 ? 1 : 0,
    },
  output: (value) =>
    value && {
      Address1: value.Address1,
      Address2: value.apt ?? '',
      City: value.City,
      State: value.State,
      ZipCode: value.ZipCode,
    },
};

const AddressInput = ({
  name,
  label,
  placeholder = '',
  defaultValue,
  control,
  rules,
  disabled,
  error,
  containerClassName,
  column = false,
  transform,
  international,
  country,
  optional = false,
  extraOptionLi = null,
}) => {
  const { setInputText, search } = useDebouncedSearch(
    async (value) => {
      const { data } = await axios.get(
        international ? globalExpressEntryEndpoint : expressEntryEndpoint,
        {
          params: {
            id: config.melissaData.licenseKey,
            format: 'json',
            maxrecords,
            country,
            ff: value,
          },
        }
      );

      const addresses = international
        ? data.Results.map(
            ({
              Address: {
                Address1,
                Locality,
                AdministrativeArea,
                PostalCode,
                SubBuilding,
              },
            }) => {
              PostalCode =
                country === 'US' ? PostalCode.substr(0, 5) : PostalCode;

              const SuiteList = SubBuilding.split(',');
              const SuiteName =
                SuiteList.length > 1 ? SuiteList[0].split(' ')[0] : '';
              const suiteInAddress = SuiteList.length === 1;

              return {
                Address1,
                City: Locality,
                State: AdministrativeArea,
                ZipCode: PostalCode,
                SuiteName,
                SuiteList,
                SuiteCount: SuiteName === '' ? 0 : SuiteList.length,
                formatted: `${Address1}, ${Locality}, ${AdministrativeArea} ${PostalCode}`,
                apt: suiteInAddress ? SuiteList[0] : null,
              };
            }
          )
        : data.Results.map(
            ({
              Address: {
                AddressLine1,
                City,
                State,
                PostalCode,
                SuiteName: suiteName,
                SuiteList,
                SuiteCount,
              },
            }) => {
              const ZipCode = PostalCode.substr(0, 5);
              const SuiteName =
                SuiteList.length > 0 && SuiteList[0] !== ''
                  ? suiteName || SuiteList[0].split(' ')[0]
                  : '';
              const suiteInAddress = SuiteList.length === 1;

              return {
                Address1: AddressLine1,
                City,
                State,
                ZipCode,
                SuiteName,
                SuiteList,
                SuiteCount,
                formatted: `${AddressLine1}, ${City}, ${State} ${ZipCode}`,
                apt: suiteInAddress ? SuiteList[0] : null,
              };
            }
          );

      return addresses.filter(
        (addr) =>
          !addresses.find(
            (a) =>
              a.formatted === addr.formatted &&
              a.SuiteCount !== addr.SuiteCount &&
              addr.SuiteCount === 0
          )
      );
    },
    {},
    [international, country]
  );

  const [aptSearch, setAptSearch] = useState('');
  const [aptError, setAptError] = useState(null);
  const [focus, setFocus] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);
  const [aptCurrentValue, setAptCurrentValue] = useState('');
  const transformIn = transform?.input ?? defaultTransform.input;
  const transformOut = transform?.output ?? defaultTransform.output;
  const validate = (val) => {
    if (val?.SuiteCount > 1 && !val.apt) {
      if (aptSearch === '') {
        setAptError(`${val.SuiteName || 'Unit'} # is required.`);
      } else {
        setAptError(`${val.SuiteName || 'Unit'} # is incorrect.`);
      }
      return false;
    } else {
      setAptError(null);
      return true;
    }
  };
  const {
    field: { value, onChange, onBlur, ref },
  } = useController({
    name,
    control,
    rules: { setValueAs: transformOut, validate, ...rules },
  });
  const defaultSelectedItem = transformIn(value);
  const {
    isOpen,
    selectedItem,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
  } = useCombobox({
    items: search.result || [],
    itemToString: (item) => item?.formatted,
    onInputValueChange: (change) => {
      if (change.selectedItem?.formatted !== change.inputValue) {
        setInputText(change.inputValue);
        setAptCurrentValue('');
        onChange(null);
      }
    },
    onSelectedItemChange: (change) => {
      onChange(change.selectedItem);
      setAptCurrentValue('');
    },
    defaultSelectedItem,
  });

  useEffect(() => {
    if (country && !componentLoading) {
      setInputText('');
      setInputValue('');
      onChange(null);
    }
    setComponentLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    setComponentLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAptChange = (apt) => {
    const { Address1, City, State, ZipCode } = selectedItem;
    onChange({
      ...selectedItem,
      Address1: `${Address1}`,
      Address2: `${apt}`,
      formatted: `${Address1} ${apt}, ${City}, ${State} ${ZipCode}`,
      apt,
    });
  };

  return (
    <div
      className={classNames(
        'flex flex-col',
        !column && 'sm:flex-row',
        containerClassName
      )}
    >
      <div
        className={`flex flex-col relative ${
          selectedItem?.SuiteCount > 1 && !column
            ? 'w-full sm:w-4/5 pr-0 sm:pr-2'
            : 'w-full'
        }`}
      >
        {label && (
          <label
            htmlFor={name}
            className={classNames(
              'font-bold text-xs absolute -top-2 left-4',
              error
                ? 'text-error'
                : focus || isOpen
                ? 'text-violet'
                : 'text-input-light'
            )}
          >
            <span className="bg-white px-1">{label}</span>
          </label>
        )}
        <div {...getComboboxProps()}>
          <input
            type="text"
            aria-label={label}
            className={classNames(
              'w-full h-11 px-4 text-sm text-input border rounded-lg',
              error
                ? 'border-error ring-error-light'
                : 'border-input-lighter ring-input-light-faded focus:border-violet ring-violet-faded'
            )}
            {...getInputProps({
              name,
              placeholder,
              disabled,
              onBlur,
              ref,
            })}
            {...debugData({ input: 'address' })}
            autoComplete="off"
            aria-invalid={error ? 'true' : 'false'}
            {...(error && { 'aria-describedby': `${name}-error` })}
            required={!optional}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            data-testid={`${name}.addressInput`}
          />
          <DropdownMenu
            id={`${name}-list`}
            isOpen={isOpen}
            disabled={disabled}
            error={error}
            options={search?.result || []}
            searching={search?.loading}
            highlightedIndex={highlightedIndex}
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
            getItemValue={(item) => item.formatted}
            extraOptionLi={extraOptionLi}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
        </div>
        {error && (
          <span id={`${name}-error`} className="text-error text-xs mt-1 ml-4">
            {error}
          </span>
        )}
      </div>

      <AptInput
        name={name + 'UnitNumber'}
        label={
          selectedItem?.SuiteName === '#' || !selectedItem?.SuiteName
            ? 'Unit #'
            : `${selectedItem?.SuiteName} #`
        }
        disabled={disabled || !value}
        aptList={selectedItem?.SuiteList?.filter((apt) => apt !== '') || []}
        onChange={handleAptChange}
        error={aptError}
        column={column}
        search={aptSearch}
        setSearch={setAptSearch}
        optional={optional}
        currentValue={aptCurrentValue}
        setCurrentValue={setAptCurrentValue}
      />
    </div>
  );
};

export default AddressInput;
