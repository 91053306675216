import { useResponsiveVariant } from 'src/lib/hooks';
import Button from '../Button';
import { twMerge } from 'src/lib/mergeTailwind';

const noop = () => {};

const Back = ({
  className,
  onClick,
  hideLabel,
  hideIcon = false,
  label: propLabel,
  icon = 'chevronLeft',
  disabled = false,
  rounded = false,
}) => {
  const defaultLabel = useResponsiveVariant({
    default: 'Go Back',
    xs: 'Go Back',
  });

  const label = propLabel ?? defaultLabel;

  return (
    <Button
      className={twMerge(
        !rounded && 'rounded-r-none w-120px xs:w-141px',
        rounded && 'w-151px',
        'justify-center px-auto',
        !hideIcon && icon && 'xs:pr-17px',
        hideLabel && 'pr-0 xs:pr-0',
        'focus:ring-offset-violet font-extrabold border-white h-51px pl-0',
        className
      )}
      fontFamily="font-heading"
      onClick={onClick ?? noop}
      disabled={disabled}
      iconLeft={!hideIcon ? icon : null}
      aria-label={label}
      data-testid="backButton"
    >
      {!hideLabel && label}
    </Button>
  );
};

export default Back;
