import dayjs from 'dayjs';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const unroundedFormatter = (numero) => {
  const partes = numero.toString().split('.');
  const parteEntera = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const parteDecimal = partes[1] ? `.${partes[1]}` : '.';
  return `${parteEntera}${parteDecimal}`;
};

export const identityTransform = {
  input: (v) => v,
  output: (v) => v,
};

export const currencyTransform = {
  input: (v) =>
    v || v === 0 ? formatter.format(v).replace(/\D00(?=\D*$)/, '') : null,
  output: (v) => v && Number(v.replace(/\$|,/g, '')),
};

export const unroundedTransform = {
  input: (v) => {
    if (typeof v === 'string' && v.includes('.') && !/^\d+\.\d{2}$/.test(v)) {
      return `$${unroundedFormatter(v)}`;
    }
    return v || v === 0
      ? formatter.format(v).replace(/\D00(?=\D*$)/, '')
      : null;
  },
  output: (v) => v && v.replace(/\$|,/g, ''),
};

export const dateTransform = {
  input: (v) => v && dayjs(v).format('MM/DD/YYYY'),
  output: (v) => {
    const date = dayjs(v);

    return v && date.isValid() ? date.format('YYYY-MM-DD') : null;
  },
};

export const phoneNumberTransform = {
  transform: (v) => {
    let phoneNumber =
      v && v.replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
    return `${phoneNumber[1]}-${phoneNumber[2]}-${phoneNumber[3]}`;
  },
};

export const coveragesTransform = {
  input: (inputValue) => {
    if (!inputValue) {
      return;
    }
    const value = JSON.parse(JSON.stringify(inputValue));

    if (value.Coverages) {
      value.Coverages.CoverageA = currencyTransform.input(
        value?.Coverages?.CoverageA
      );
      value.Coverages.CoverageC = currencyTransform.input(
        value?.Coverages?.CoverageC
      );
      value.Coverages.CoverageD = currencyTransform.input(
        value?.Coverages?.CoverageD
      );
    }

    if (value.OptionalCoverages) {
      value.OptionalCoverages.BusinessLimit = currencyTransform.input(
        value?.OptionalCoverages?.BusinessLimit
      );

      if (value.OptionalCoverages.ScheduledPersonalProperty) {
        value?.OptionalCoverages?.ScheduledPersonalProperty.forEach((spp) => {
          spp.SPPLimit = currencyTransform.input(spp.SPPLimit);
          delete spp.SPPFileStream;
        });
      }
    }

    return value;
  },
  output: (v) => {
    if (!v?.Coverages) {
      return {};
    }
    v.Coverages.CoverageA = currencyTransform.output(v?.Coverages?.CoverageA);
    v.Coverages.CoverageC = currencyTransform.output(v?.Coverages?.CoverageC);
    v.Coverages.CoverageD = currencyTransform.output(v?.Coverages?.CoverageD);
    v.OptionalCoverages.BusinessLimit = currencyTransform.output(
      v?.OptionalCoverages?.BusinessLimit
    );
    if (v?.OptionalCoverages?.ScheduledPersonalProperty?.length > 0) {
      v?.OptionalCoverages?.ScheduledPersonalProperty.forEach((spp) => {
        spp.SPPLimit = currencyTransform.input(spp.SPPLimit);
      });
    }
    return v;
  },
};

const windMitFormMapping = {
  RoofCover: {
    'A': 'FBC Equivalent',
    'B': 'FBC Equivalent',
    'C': 'Non-FBC Equivalent',
    'D': 'Non-FBC Equivalent',
    'Inspector Did Not Specify': 'Unknown',
    'Level B': 'FBC Equivalent',
    'Level A': 'Non-FBC Equivalent',
  },
  RoofDeckAttachment: {
    'A': `6d @ 6"/12`,
    'B': `8d @ 6"/12`,
    'C': `8d @ 6"/6" Dimensional Lumber Deck`,
    'D': 'Reinforced Concrete Roof Deck',
    'E': 'Unknown',
    'F': 'Unknown',
    'G': 'Unknown',
    'Inspector Did Not Specify': 'Unknown',
    'Level A': `6d @ 6"/12`,
    'Level B': `8d @ 6"/12`,
    'Level C': 'Reinforced Concrete Roof Deck',
  },
  RoofToWallConnection: {
    'A': 'Toe Nails',
    'B': 'Clips',
    'C': 'Single Wraps',
    'D': 'Double Wraps',
    'E': 'Reinforced Concrete Roof Deck',
    'F': 'Unknown',
    'G': 'Unknown',
    'H': 'Unknown',
    'Inspector Did Not Specify': 'N/A',
  },
  RoofGeometry: {
    'A': 'Hip',
    'B': 'Flat',
    'C': 'Gable',
    'Inspector Did Not Specify': 'Other',
  },
  SecondaryWaterResistance: {
    'A': 'Yes SWR',
    'B': 'No SWR',
    'C': 'Unknown',
    'Inspector Did Not Specify': 'Unknown',
    'Underlayment': 'Yes SWR',
    'Foamed Adhesive': 'No SWR',
  },
  OpeningProtection: {
    'A.1': 'Hurricane - Windows or All',
    'A.2': 'Hurricane - Windows or All',
    'A.3': 'Hurricane - Windows or All',
    'B.1': 'Basic - Windows or All',
    'B.2': 'Basic - Windows or All',
    'B.3': 'Basic - Windows or All',
    'C.1': 'None',
    'C.2': 'None',
    'C.3': 'None',
    'N.1': 'None',
    'N.2': 'None',
    'N.3': 'None',
    'X': 'None',
    'Inspector Did Not Specify': 'Unknown',
    'Class A (Hurricane Impact)': 'Hurricane - Windows or All',
    'Class B (Basic Impact)': 'Basic - Windows or All',
  },
  TerrainExposureCategory: {
    'Exposure C': 'TerrainC',
    'Exposure B': 'TerrainB',
  },
  WindSpeed: {
    '≥100': '100',
    '≥110': '110',
    '≥120': '120',
  },
  WindDesign: {
    '≥100': '100',
    '≥110': '110',
    '≥120': '120',
  },
};

export const windMitTransform = {
  input: (v) => {
    if (!v?.RoofCover) {
      return {};
    }
    v.RoofCover = windMitFormMapping.RoofCover[v.RoofCover];
    v.RoofDeckAttachment =
      windMitFormMapping.RoofDeckAttachment[v.RoofDeckAttachment];
    v.RoofToWallConnection =
      windMitFormMapping.RoofToWallConnection[v.RoofToWallConnection];
    v.RoofGeometry = windMitFormMapping.RoofGeometry[v.RoofGeometry];
    v.SecondaryWaterResistance =
      windMitFormMapping.SecondaryWaterResistance[v.SecondaryWaterResistance];
    v.OpeningProtection =
      windMitFormMapping.OpeningProtection[v.OpeningProtection];
    v.TerrainExposureCategory =
      windMitFormMapping.TerrainExposureCategory[v.TerrainExposureCategory];
    v.WindSpeed = windMitFormMapping.WindSpeed[v.WindSpeed];
    v.WindDesign = windMitFormMapping.WindDesign[v.WindDesign];
    return v;
  },
};

export const missingInfoTransform = {
  input: (v) => {
    let MissingInfo = [];

    if (!(v.YearBuilt > 0)) {
      MissingInfo.push('YearBuilt');
    }
    if (!(v.RoofYear > 0)) {
      MissingInfo.push('RoofYear');
    }
    if (!(v.SquareFeet > 0)) {
      MissingInfo.push('SquareFeet');
    }
    if (!v.RoofMaterial) {
      MissingInfo.push('RoofMaterial');
    }
    if (!v.RoofShape) {
      MissingInfo.push('RoofShape');
    }
    if (!v.Construction) {
      MissingInfo.push('Construction');
    }
    if (!v.DwellingType) {
      MissingInfo.push('DwellingType');
    }
    return MissingInfo;
  },
};
