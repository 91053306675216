import { useEffect } from 'react';
import { useState } from 'react';
import { useController } from 'react-hook-form';

const useFile = ({ name, control, rules, defaultValue, options }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  rules = {
    ...rules,
    validate: {
      ...rules?.validate,
      fileError: () => error,
    },
  };

  const { field } = useController({ name, control, rules, defaultValue });

  const { value, onChange, ref } = field;
  useEffect(() => {
    onChange(defaultValue);
    setFile(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!value) {
      setFile(null);
      ref.current.value = null;
    }
  }, [value, ref]);

  const openFileBrowser = () => {
    ref.current.click();
  };
  const removeSelectedFile = () => {
    setError(null);
    setFile(null);
    ref.current.value = null;
    onChange(null);
  };

  const validateFileExtension = (file) => {
    const isValid = options.extensions.some(
      (x) => file.name.split('.').pop() === x.split('.').pop()
    );

    return isValid;
  };

  const validateFileSize = (file) => {
    const isValid = file.size < options.limitSize && file.size > 0;
    return isValid;
  };

  const validateFileNameLength = (file) => {
    const isValid = file.name.length <= options.maxFileNameLength;
    return isValid;
  };

  return {
    file,
    setFile,
    error,
    setError,
    field,
    openFileBrowser,
    removeSelectedFile,
    validateFileExtension,
    validateFileSize,
    validateFileNameLength,
  };
};

export default useFile;
