import { PRODUCTS } from '../products';

export const PROGRESS = {
  EXPLORE_PRICING: {
    totalNotches: 7,
    filledNotches: 1,
    label: 'Explore Our Pricing',
  },
  PROCEED: {
    totalNotches: 7,
    filledNotches: 1,
    label: 'Proceed with Quote',
  },
  INTERVIEW: {
    totalNotches: 7,
    filledNotches: 2,
    label: 'Interview Questions',
  },
  COVERAGES: {
    totalNotches: 7,
    filledNotches: 3,
    label: 'Adjust Coverages',
  },
  DISCOUNTS: {
    totalNotches: 7,
    filledNotches: 4,
    label: 'Apply Discounts',
  },
  UNDERWRITING: {
    totalNotches: 7,
    filledNotches: 5,
    label: 'Final Steps',
  },
  FINAL_STEPS: {
    totalNotches: 7,
    filledNotches: 6,
    label: 'Final Steps',
  },
  REVIEW_PAY: {
    totalNotches: 7,
    filledNotches: 7,
    label: 'Review & Pay',
  },
  SUCCESS: {
    totalNotches: 7,
    filledNotches: 7,
    label: 'Success!',
  },
};

export const WIND_MIT = {
  UNIFORM: 'Uniform',
  BUILDING: 'Building',
};

export const MISSING_INFO = {
  YEAR_BUILT: 'YearBuilt',
  SQUARE_FEET: 'SquareFeet',
  ROOF_YEAR: 'RoofYear',
  ROOF_MATERIAL: 'RoofMaterial',
  ROOF_SHAPE: 'RoofShape',
  CONSTRUCTION: 'Construction',
  DWELLING_TYPE: 'DwellingType',
};

export const PAGES = {
  PasswordlessPage: 'PasswordlessPage',
  QuoteCreationPage: 'QuoteCreationPage',
  OccupancyPage: 'OccupancyPage',
  CondoStoriesPage: 'CondoStoriesPage',
  YearBuiltPage: 'YearBuiltPage',
  OnHistoricalRegisterPage: 'OnHistoricalRegisterPage',
  RoofMaterialPage: 'RoofMaterialPage',
  RoofShapePage: 'RoofShapePage',
  BuildingMaterialPage: 'BuildingMaterialPage',
  ExteriorWallFinishPage: 'ExteriorWallFinishPage',
  FoundationPage: 'FoundationPage',
  InitialQuotePage: 'InitialQuotePage',
  PackageSelectionPage: 'PackageSelectionPage',
  EffectiveDatePage: 'EffectiveDatePage',
  PreviousCarrierPage: 'PreviousCarrierPage',
  UnderwritingQuestionsPage: 'UnderwritingQuestionsPage',
  AdjustCoveragesPage: 'AdjustCoveragesPage',
  CoveragesPage: 'CoveragesPage',
  DeductiblesPage: 'DeductiblesPage',
  OptionalCoveragesPage: 'OptionalCoveragesPage',
  OptionalCoveragesMiscPage: 'OptionalCoveragesMiscPage',
  OptionalCoveragesLimitedLossPage: 'OptionalCoveragesLimitedLossPage',
  OptionalCoveragesPropertyPage: 'OptionalCoveragesPropertyPage',
  DiscountsPage: 'DiscountsPage',
  PaperlessPage: 'PaperlessPage',
  FormSelectionPage: 'FormSelectionPage',
  UniformUploadPage: 'UniformUploadPage',
  UniformQuestionnairePage: 'UniformQuestionnairePage',
  BuildingUploadPage: 'BuildingUploadPage',
  BuildingQuestionnairePage: 'BuildingQuestionnairePage',
  SavingsPage: 'SavingsPage',
  FirstMortgagePage: 'FirstMortgagePage',
  SecondMortgagePage: 'SecondMortgagePage',
  TrustPage: 'TrustPage',
  AdditionalSelectionPage: 'AdditionalSelectionPage',
  CoApplicantPage: 'CoApplicantPage',
  AdditionalInsuredPage: 'AdditionalInsuredPage',
  MailingAddressPage: 'MailingAddressPage',
  ReviewPage: 'ReviewPage',
  PaymentPage: 'PaymentPage',
  CompletionPage: 'CompletionPage',
};

export const ERROR_TYPES = {
  REFERRED_TO_EXT_AGENT: 'Referred to External Agent',
  REFERRED_TO_INT_AGENT: 'Referred to Internal Agent',
  ISSUANCE_FAILURE: 'Issuance Failure',
};

export const COLLECTION_METHOD = {
  RECURRING: 'EFTC',
  ADHOC: 'Paper',
};

export const LOADING_MESSAGE = {
  ACCOUNT_CREATION: 'Thank you! One moment while we get your account set up...',
  RESUMPTION: 'Welcome back! One sec while we access your quote-in-progress...',
  PAYMENT_PREFETCH: 'One moment while we fetch your payment options...',
  PAYMENT_PROCESSING: 'Just a moment while your payment finishes processing.',
};

export const scheduledItemTypes = [
  'Bicycles',
  'Cameras & Projection Equipment - Personal',
  'Cameras & Projection Equipment - Professional',
  'Coins - In Safe',
  'Coins - No Safe',
  'Collectibles',
  'Fine Arts and Antiques with Breakage',
  'Fine Arts and Antiques No Breakage',
  'Furs',
  'Golfers Equipment',
  'Guns: Collectible',
  'Guns: Fired (Not Collectible)',
  'Jewelry',
  'Jewelry: In Vaults or Safety Deposit Box',
  'Musical Instruments - Personal',
  'Musical Instruments - Professional',
  'Other Sports Equipment',
  'Silverware',
  'Stamps - In Safe',
  'Stamps - No Safe',
].map((opt) => ({
  title: opt,
  id: opt,
}));

export default {
  PROGRESS,
  WIND_MIT,
  MISSING_INFO,
  PAGES,
  ERROR_TYPES,
  COLLECTION_METHOD,
  LOADING_MESSAGE,
  PRODUCTS,
};
